'use client'
import { ReactNode, SetStateAction, createContext, useMemo, useState } from 'react'
import { SearchType } from './types'
type SearchContextProps = {
    searchType?: SearchType
    carouselIndex?: number
    setSearchType: React.Dispatch<SetStateAction<SearchType | undefined>>
    setCarouselIndex: React.Dispatch<SetStateAction<number>>
}

export const SearchTypeContext = createContext({
    searchType: 'gifs',
    carouselIndex: 0,
} as SearchContextProps)

const SearchTypeContextManager = ({ children }: { children: ReactNode }) => {
    const [searchType, setSearchType] = useState<SearchType>()
    const [carouselIndex, setCarouselIndex] = useState(0)
    const value = useMemo(
        () => ({ searchType, setSearchType, carouselIndex, setCarouselIndex }),
        [carouselIndex, searchType]
    )
    return <SearchTypeContext.Provider value={value}>{children}</SearchTypeContext.Provider>
}

export default SearchTypeContextManager
